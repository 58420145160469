<template>
  <div id="home">
    <banner-section />
    <offer-section />
    <service-section />
    <testimonial-section />
  </div>
</template>

<script>
const BannerSection = () => import("./Section/BannerSection.vue");
const OfferSection = () => import("./Section/OfferSection.vue");
const ServiceSection = () => import("./Section/ServiceSection.vue");
const TestimonialSection = () => import("./Section/TestimonialSection.vue");

export default {
  name: "Home",
  components: {
    BannerSection,
    OfferSection,
    ServiceSection,
    TestimonialSection,
  },
};
</script>
